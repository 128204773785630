<template>
  <div class="card">
    <div class="container">
      <b-modal ref="addNewsFeedItemsModal" hide-footer>
        <addNewsFeedItems
          @closeAddNewsFeedItems="toggleAddNewsFeedItems()"
          @addNewsFeedItemsSuccess="addNewsFeedItemsSuccess()"
          :section="section"
        ></addNewsFeedItems>
      </b-modal>
      <b-modal ref="editNewsFeedItemsModal" hide-footer>
        <editNewsFeedItems
          :editingNewsFeedItems="currentNewsFeedItems"
          :section="section"
          @closeEditNewsFeedItems="toggleEditNewsFeedItems()"
          @editNewsFeedItemsSuccess="editNewsFeedItemsSuccess()"
        ></editNewsFeedItems>
      </b-modal>
      <div class="row">
        <h2>
          <span :v-if="section">{{ section }} </span>
        </h2>
      </div>
      <div class="row">
        <button
          @click="toggleAddNewsFeedItems()"
          type="button"
          class="btn btn-success"
        >
          Add
        </button>
      </div>
      <div class="row">
        <DataBlocks
          @itemSelected="itemSelected"
          :data="items"
          :showImage='true'
          imageField='ThumbImage'
          :fields="['Title']"
        />
      </div>
    </div>
  </div>
</template>
<script>
import addNewsFeedItems from "../../components/addNewsFeedItems";
import editNewsFeedItems from "../../components/editNewsFeedItems";
import VueNotifications from "vue-notifications";
import DataBlocks from "@/components/custom/dataBlocks.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  mounted() {
    this.section = this.$route.params.section;
    this.filterItems();
  },
  data() {
    return {
      section: null,    
      allItems: [],
      items: [],
      editMode: false,
      addNewsFeedItemsOpen: false,
      editNewsFeedItemsOpen: false,
      currentNewsFeedItems: {},
    };
  },
  components: {
    addNewsFeedItems,
    editNewsFeedItems,
    DataBlocks,
  },
  created: function () {
    this.getNewsFeedItems();
    this.addNewsFeedItemsOpen = false;
    this.editNewsFeedItemsOpen = false;
  },
  methods: {
    filterItems() {
      switch (this.section) {
        case "News":
          this.items = this.allItems.filter((f) => f.NewsFeedItemTypeId == 1);
          break;
        case "Events":
          this.items = this.allItems.filter((f) => f.NewsFeedItemTypeId == 2);
          break;
        default:
          this.items = this.allItems;
          break;
      }
    },
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    getNewsFeedItems() {
      var payload = {
        success: (response) => {
          this.allItems = response.data;
          this.filterItems();
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getNewsFeedItems", payload);
    },
    editItem(NewsFeedItems) {
      this.toggleEditNewsFeedItems();
      this.currentNewsFeedItems = NewsFeedItems;
    },
    toggleAddNewsFeedItems() {
      if (this.addNewsFeedItemsOpen) {
        this.$refs.addNewsFeedItemsModal.hide();
      } else {
        this.$refs.addNewsFeedItemsModal.show();
      }
      this.addNewsFeedItemsOpen = !this.addNewsFeedItemsOpen;
    },
    addNewsFeedItemsSuccess() {
      this.toggleAddNewsFeedItems();
      miniToastr["success"]("NewsFeedItems Added", "Success", 1000, null);
      this.getNewsFeedItems();
       this.$store.dispatch("updateSearchIndexCache");
    },
    itemSelected(item) {
      this.editMode = true;
      this.currentNewsFeedItems = item;
      
        this.$refs.editNewsFeedItemsModal.show();
        this.$refs.addNewsFeedItemsModal.hide();
      
      this.editNewsFeedItemsOpen = !this.editOthersBannersOpen;
    },
    toggleEditNewsFeedItems() {
      if (this.editNewsFeedItemsOpen) {
        this.$refs.editNewsFeedItemsModal.hide();
      } else {
        this.$refs.addNewsFeedItemsModal.show();
      }
      this.editNewsFeedItemsOpen = !this.editNewsFeedItemsOpen;
    },
    editNewsFeedItemsSuccess() {
      this.toggleEditNewsFeedItems();
      miniToastr["success"]("NewsFeedItems Edited", "Success", 1000, null);
      this.getNewsFeedItems();
       this.$store.dispatch("updateSearchIndexCache");
    },
  },
  watch: {
    $route(to, from) {
      this.section = this.$route.params.section;
      this.filterItems();
      this.addNewsFeedItemsOpen = false;
      this.editNewsFeedItemsOpen = false;
    },
  },
};
</script>
<style scoped>
.NewsFeedItemsThumbnail {
  height: 50px;
}
</style>
